@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
	font-family: 'Quattrocento Sans', sans-serif;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5rem;
	padding: 0;
	margin: 0;
	min-height: 100%;
	background-color: #fff /*rgb(2,5,36)*/;
}

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #ACAEC8;
	height: calc(100vh - 6rem);
}

#root {
	padding: 0;
	min-height: 100%;
	position: relative;
}

header {
	width: 100%;
	margin: 0;
	padding: 0;
	text-align: center;
	position: absolute;
	height: 150px;
	z-index: 100;
	pointer-events: none;
}

/*/////
	
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
	
/////*/

header.nobg {
	background: none;
}

header.nobg .links a {
	color: #fff;	
}

header #logo {
	position: absolute;
	top: 1rem;
	left: 1.5rem;
	width: 200px;
}

header 	.links {
	position: absolute;
	top: 2em;
	right: 1.5rem;
	width: calc(100% - 220px);	
	text-align: right;
	pointer-events: all;
}

header 	.links a {
	display: inline-block;
	padding: .5em 1.4em;
	margin-left: 1em;
	color: #000;
	box-sizing: border-box;
	text-decoration: none;
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	font-size: .8em;	
	text-align: center;
}

header .links a.active {
	border-bottom: 3px solid #ff0000;
    cursor: default;
}

header .links .live {
    border-bottom: none !important;
    background-color: #ff0000;
    color: #fff;
}

header .links .live svg {
	width: 7px;
	margin-right: 3px;
}

header 	.links a img {
	display: inline-block;
	margin: 0 .5em .1em 0;
	width: 15px;
	vertical-align: middle;
}

main {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 80px 0 0 0;
	box-sizing: border-box;
	z-index: 50;
	
}

main .content {
	margin: 3rem 3rem 100px 3rem;
}

main .content.intro {
	margin: 7em auto 0 auto;
	padding-bottom: 1em;
	width: 60%;
	color: #fff;
}

main .content.intro p {
	font-size: 1.2rem;	
}

main .content.intro .logo {
	margin-bottom: 4em;
	width: 100%;
}

main .content.intro img.streak {
	width: 55%;
	margin: 0 auto;
	display: block;
}

main .content.intro .button {
	background-color: #ea3223;
	font-weight: bold;
	padding: .7rem 1.5rem;
	border-radius: 4px;
	text-decoration: none;
	color: #fff;
	font-size: 1.3rem;
	margin: 3rem auto 2rem auto;
	width: 200px;
	text-align: center;
	display: block;
	font-weight: 700;
	position: relative;
	cursor: pointer;
}

main .content.intro h1, main .content.intro h2, main .content.intro h3, main .content.intro h4, main .content.intro h5 {
	color: #fff;
}

footer {
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	width: 100%;
	padding: 2rem 3rem;
	box-sizing: border-box;
	color: #333;
	text-align: center;
	display: block;
	position: fixed;
	font-size: .9rem;
	bottom: 0;
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.8) 90%);
	pointer-events: none;
	filter: drop-shadow(0 0 5px #fff);
}

footer.intro {
	position: relative;
	background: none;
	color: rgba(255,255,255,.5);
}

footer span {
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	white-space: nowrap;
}

#footerLogo {
	position: fixed;
	right: 3rem;
	bottom: 6rem;
	width: 20%;
	filter: drop-shadow(0 0 8px #fff);
}

#pagebg {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: -2;
}

#pagebg-blue {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: url('./assets/twinebg.jpg') top center no-repeat;
	background-size: cover;
	background-color: rgb(2,5,36);
	z-index: -2;
}

h1, h2, h3, h4 {
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	line-height: 1.3em;
	color: #000;
}

h1.icewind {
	transform: translateX(-50%) translateY(70%) !important;
}

h1.label {
	display: inline-block;
	margin: 0 auto;
	position: relative;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 100;
	color: #fff;
	background: linear-gradient(90deg, rgba(0,0,0,0) 8%, rgba(0,0,0,1) 8.1%, rgba(0,0,0,1) 91.9%, rgba(0,0,0,0) 92%);
	min-width: 300px;
	text-align: center;
}

h1.label span:first-child {
	width: 30px;
	height: 40px;
	display: block;
	background: url('./assets/banner-end-l.svg') top right no-repeat;
	background-size: contain;
	float: left;
}

h1.label span:nth-child(2) {
	display: inline-block;
	height: 40px;
	width: auto;
	background-color: #000000;
	padding: 0 1em;
	line-height: 40px;
}

h1.label span:last-child {
	background-color: #000000;
	width: 30px;
	height: 40px;
	display: block;
	background: url('./assets/banner-end-r.svg') top left no-repeat;
	background-size: contain;
	float: right;
}

.art {
	position: relative;
	display: block;
	width: 100%;
}

img.hotspot {
	z-index: 10;
	position: absolute;
}

img path {
	pointer-events: visibleFill;
	cursor: pointer;
}




.embedModal {
	position: relative;
	width: auto;
	height: calc(100% - 200px);
	top: 200px;
	background-color: transparent;
	box-sizing: border-box;
	overflow: scroll;
	margin: 0 auto;
	text-align: center;
}




.insetModal {
	position: relative;
	width: 100%;
	height: auto;
	padding: 0;
	background-color: transparent;
	box-sizing: border-box;
	overflow: scroll;
	transform-origin: top center;
	top: 4.45em;
	margin: 0 auto;
}

.insetModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.7) 10%, rgba(0,0,0,.7) 100%);
	overflow: hidden;
}

.insetModalBody {
	position: relative;
	display: flex;
	flex-direction: row;
}

.insetModalBody .image {
	position: relative;
	flex: 3;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	line-height: 0;
	box-shadow: 0 0 12px rgba(0,0,0,.13);
	background-color: #111;
	background-size: cover;
}

.insetModalBody .image img {
	margin: auto 0;	
}

.insetModalBody .sidebar {
	position: relative;
	flex: 1;
	display: flex;
	padding: 3em;
	flex-direction: column;
	vertical-align: top;
	background-color: #fff;
	box-shadow: 0 0 12px rgba(0,0,0,.13);
	color: #000;
}



.iframeModal {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0;
	background-color: transparent;
	box-sizing: border-box;
	overflow: scroll;
	transform-origin: top center;
	top: 0;
	transform: translateX(-50%) !important;
	left: 50%;
}

.iframeModalBody {
	position: relative;
	height: 100%;
}

.iframeModalBody iframe {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	border: none;
	background: url('./assets/twinebg.jpg') top left no-repeat;
	background-size: cover;
}




.modal {
	position: relative;
	width: 75%;
	height: 100%;
	padding: 0;
	margin: 0 auto;
	background-color: transparent;
	box-sizing: border-box;
	z-index: 600;
}

.modalOverlay button {
	position: relative;
	margin-right: 1em;
}

.modalOverlay {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 90px);
	z-index: 500;
	background: linear-gradient(180deg, rgba(255,255,255,1) 5%, rgba(255,255,255,.9) 25%);
	overflow: scroll;
}

.modalHeader {
	display: block;
	margin-bottom: 1em;
	width: 100%;
	position: relative;
}

.modalHeader h1 {
	transform: translateX(-50%) translateY(-13%);
}

.modalHeader a {
	position: absolute;
}

.modalHeader a.backLink {
	left: 0;
}

.modalHeader a.discord {
	position: absolute;
	right: 0;
	display: inline-block;
}

.modalHeader a.discord .back, .modalHeader a.backLink .back {
	position: unset;
	top: unset;	
}

button.back {
	font-size: 1.1em;
	font-weight: 700;
	padding: .5em;
	color: #fff;
	background-color: rgba(0,0,0,1);
	border: none;
	cursor: pointer;
	position: fixed;
	z-index: 1100;
	top: 105px;
	left: 2em;
}

.modalHeader h2 {
	display: block;
	position: relative;
	font-size: 2.3em;
	z-index: 100;
	text-align: center;
	width: 100%;
	top: 0;
	margin: 0;
}

.modalHeader b {
	cursor: pointer;
	margin-right: .3em;
}

.modalBody {
	position: relative;
	width: 100%;
	margin: 0 1.5% 0 0;
	padding: 0;
	display: inline-block;
	line-height: 0;
	box-shadow: 0 0 12px rgba(0,0,0,.13);
	background-size: cover;
}



.modalCentered {
	position: relative;
	width: 60%;
	display: block;
	vertical-align: top;
	background-color: #fff;
	box-shadow: 0 0 12px rgba(0,0,0,.13);
	margin: 5em auto 0 auto;
}

.modalBody > iframe, .insetModalBody iframe {
	position: relative;
	width: 100%;
	height: 700px;
		border: none;
		background: url('./assets/twinebg.jpg') top left no-repeat;
}

.modalCentered > .header {
	display: block;
	background: linear-gradient(180deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 1) 100%);
	height: 75px;
	color: #fff;
	position: relative;
}

.modalCentered > .header button {
	position: absolute;
	margin: 0;
	top: 1em;
	right: 1em;
}

.modalCentered > .header img.crest {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100px;
	z-index: 100;
}

.modalCentered > .header span {
	position: absolute;
	z-index: 100;
	display: block;
	width: 100%;
	padding: 1em;
	top: 40%;
	text-align: center;
	box-sizing: border-box;
}

.modalCentered > .header .bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 120px;
	z-index: 0;
	overflow: hidden;
}

.modalCentered > .header .bg img {
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-25%, -47%);
	width: 200%;
	opacity: .3;
}



.modalSidebar {
	position: relative;
	width: 100%;
	display: block;
	vertical-align: top;
	background-color: #fff;
	box-shadow: 0 0 12px rgba(0,0,0,.13);
}

.modalSidebar > .header {
	display: block;
	background: linear-gradient(180deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 1) 100%);
	color: #fff;
	position: relative;
	text-align: center;
	padding-bottom: 2em;
}

.modalSidebar > .header p {
	font-style: italic;
}

.modalSidebar > .header h2 {
	color: #fff;
}

.modalSidebar > .header img.crest {
	position: relative;
	margin-top: -50px;
	width: 100px;
	z-index: 100;
}

.modalSidebar > .header span {
	position: relative;
	z-index: 100;
	display: block;
	width: 70%;
	margin: 0 auto;
	padding: 1em;
	top: 40%;
	text-align: center;
	box-sizing: border-box;
}

.modalSidebar > .header p {
	width: 50%;
	margin: 0 auto 2em auto;
}

.modalSidebar > .header span img, .modalCentered > .header span img {
	width: 13px;
	margin: 0 2px;	
}

.modalSidebar > .header .bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	overflow: hidden;
}

.modalSidebar > .header .bg img {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate(-25%, 0);
	width: 50%;
	opacity: .2;
}

.modalSidebar > .header div, .modalCentered > .header div {
	width: 33%;
	display: inline-block;
	text-align: center;
}

.modalSidebar > .header div h5, .modalCentered > .header div h5 {
	margin: 0;
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	font-size: 1em;
}

.modalSidebar > .description {
	padding: 2em;	
	color: #000;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: 0 1em;
}

.modalSidebar > .description .col3 {
	padding: 2em;
	flex: 1 1 0px;
	min-width: 300px;
	box-sizing: border-box;
}

.modalSidebar > .description::after {
	content: "";
	flex: 38%;
	margin: 0;
	min-width: 33.3%;
}

.modalCentered > .description {
	padding: 2em;	
	color: #000;
	height: auto;
	overflow: auto;
}

.modalSidebar > .description b, .modalCentered > .description b {
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	text-transform: uppercase;
}

.closeButton {
	color: #ccc;
	background-color: rgba(0,0,0,.8);
	border: 1px solid rgba(255,255,255,.8);
	cursor: pointer;
	position: absolute;
	z-index: 1100;
	top: 5;
	right: 0;
	outline: none;
	padding: .7em .7em .4em .7em;
}

.revelsendContainer {
	position: fixed;
	display: block;
	background: url('./assets/revelsend.jpg') top center no-repeat;
	width: 100%;
	height: calc(100vh - 150px);
	background-size: cover;
	margin-left: -3em;
	overflow: scroll;
	transform-origin: top center;
	z-index: 1000;
}

.revelsendHeader {
	position: relative;
	text-align: center;
	display: block;
	width: 100%;
	top: 4em;
}

.revelsendHeader h2 {
	color: #fff;
}

.revelsendContainer .logoCenter {
	display: block;
	position: absolute;
	z-index: 100;
	margin: 0 auto;
	text-align: center;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
}

.revelsendGrid {
	position: relative;
	margin: 9em 0 0 0;
	padding: 4em 5% 2em 5%;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	z-index: 25;
	background-color: rgba(255,255,255,.4);
	box-sizing: border-box;
	border-top: 6px solid #fff;
	
}

.revelsendGrid .gridBox {
	margin: 1em;
	position: relative;
	display: flex;
	border: 1px solid rgba(255,255,255,.4);
	display: block;
	background-color: #000;
	padding: 0;
	flex: 1 1 0px;
	min-width: 30%;
}

.revelsendGrid .eliminated {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.7);
	z-index: 100;
	text-align: center;
	padding: 4em 2em;
	box-sizing: border-box;
	font-size: 2rem;
	color: #ff0000;
	font-weight: 900;
}

.revelsendGrid::after {
	content: "";
	flex: 35.8%;
	margin: 0;
	max-width: 33.3%;
}

.revelsendGrid .igLink {
	background-color: rgb(77, 77, 78);
	color: #fff;
	text-decoration: none;
	display: inline-block;
	vertical-align: middle;
	padding: 1em;
	line-height: 1em;
	box-sizing: border-box;
}

.revelsendGrid .igLink i {
	margin-right: .4em;
}

.revelsendGrid .gridBox .video, .revelsendGrid .gridBox .videomobile {
	width: 100%;
	background-color: #333;
	position: relative;
	padding: 0;
	text-align: center;
	box-sizing: border-box;
}

.revelsendGrid .gridBox .video {
	display: block;
}

.revelsendGrid .gridBox .videomobile {
	display: none;	
}

.revelsendGrid .gridBox .video img, .revelsendGrid .gridBox .videomobile img {
	width: 100%;
}

.revelsendGrid .gridBox .info {
	width: 100%;
	position: relative;
}

.revelsendGrid .gridBox .logo {
	width: 100px;
	position: absolute;
	top: 0;
	left: 0;
	padding: 1em;
	box-sizing: border-box;
}

.revelsendGrid .gridBox .logo img {
	width: 100%;
}

.revelsendGrid .gridBox .description {
	width: calc(100% - 100px);
	position: relative;
	left: 100px;
	padding: 1em;
	box-sizing: border-box;
}

.revelsendGrid .gridBox .description h3 {
	color: #fff;
	margin-top: 0;
}

.revelsEndOverlay {
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,.4) 90%, rgba(255,255,255,1) 100%);
	width: 100%;
	height: calc(100vh - 170px);
	z-index: 10;
	position: fixed;
	top: 170px;
	left: 0;
	pointer-events: none;
}

.illustration {
	position: relative;
	padding: 1em 0 3em 0;
	margin: 0;
	width: 100%;
	display: block;
	z-index: 20;
	box-sizing: border-box;
	color: #fff;
	text-align: center;
	background-color: rgba(0,0,0,.6);
	font-weight: 700;
	font-size: .9rem;
}

a.discord {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(-20%);
	font-size: 1.1rem;
	margin: 0;
	padding: 0;
	z-index: 100;
	display: inline-block;
	box-sizing: border-box;
}

a.discord button {
	padding: .5em 1em;
	vertical-align: middle;	
	line-height: 2em;
}

a.discord img {
	vertical-align: middle;
	margin-right: .5em;
}





#social {
	position: fixed;
	right: 2rem;
	bottom: 10em;
	width: 50px;
	text-align: center;
}

#social i {
	display: block;
	width: 100%;
	margin: 1em 0;
	opacity: .6;
}

#social i:hover {
	opacity: 1;
}

#social a {
	color: #fff;
	text-decoration: none;
}



.marketplace, .schedule {
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
	z-index: 0;
}

.marketplace .logos {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	max-width: 1400px;
	margin: 0 auto;
}

.marketplace .logos div {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 2em;
	box-sizing: border-box;
	margin: 2%;
	min-width: 200px;
}

.marketplace .logos::after {
	content: "";
	flex: 38%;
	margin: 0;
	max-width: 50%;
}

.marketplace .logos div a {
	position: relative;
	display: block;
	top: 50%;
	transform: translateY(-50%);
}

.marketplace .logos div a img {
	width: 100%;
}

.bottomGrad {
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	height: 200px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.whiteBlock {
	background-color: rgba(255,255,255, 1);
	color: #000;
	filter: drop-shadow(0 0 8px rgba(0,0,0,.2));
}

.flex2 {
	position: relative;
	display: flex;
	flex-flow: row wrap;
}

.flex2 > div {
	flex: 2;
	display: flex;
	flex-direction: column;
	padding: 2em;
	box-sizing: border-box;
	margin: 2%;
	min-width: 230px;
}

.flex2 img, .flex3 img {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.flex3 {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	text-align: center;
	max-width: 1200px;
	margin: 0 auto;
}

.flex3 > div {
	flex: 2;
	display: flex;
	flex-direction: column;
	padding: 2em;
	box-sizing: border-box;
	margin: 4em 2% 2% 2%;
	min-width: 300px;
}

.flex3::after {
	content: "";
	flex: 50%;
	margin: 0;
	max-width: 50%;
}

.imageFrame {
	height: 100%;
	margin-bottom: 1em;
}


.marketplace .button {
	background-color: #000;
	font-weight: bold;
	padding: .7rem 1.5rem;
	border-radius: 4px;
	text-decoration: none;
	color: #fff;
	font-size: 1rem;
	width: 150px;
	text-align: center;
	display: block;
	font-weight: 700;
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.marketplace .button.secondary {
	background-color: rgba(255,255,255, 1);
	border-radius: 4px;
	width: 100%;
	margin: 1rem 0;
	text-align: left;
	font-size: 1.1rem;
	line-height: 2rem;
	padding: 1rem;
	font-weight: 600;
	box-sizing: border-box;
	color: #000;
	filter: drop-shadow(0 0 8px rgba(0,0,0,.2));
}

.marketplace .button div {
	display: block;
	position: relative;
	width: calc(100% - 80px);
	left: 60px;
}

.marketplace .button span {
	white-space: nowrap;
}

.marketplace .button i.fal {
	position: absolute;
	top: 50%;
	left: 14px;
	transform: translateY(-50%);
	display: block;
	text-align: center;
	width: 50px;
	font-size: 2rem;
	vertical-align: middle;
	color: #ff0000;
}

.marketplace h2 {
	text-align: center;
	font-size: 1.8em;
}

.marketplace p {
	font-weight: 700;
	color: #000;
}

.colInset {
	margin: 0 auto;
	width: 70%;
	max-width: 1200px;
	z-index: 100;
	position: relative;
}

.schedule h3 {
	margin: 0;
}

.flex3 span {
	margin: 0 0 2em 0;
}

.schedule h2 {
	background-color: #fff;
	display: block;
	padding: .5em .7em;
	border: 1px solid #000;
	color: #000;
	margin: -2em 1em 2em 1em;
}

.schedule div:first-child h2 {
/* 	background-color: #ff0000;	 */
}



/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 2em;
  top: 2.4em;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ccc;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #000;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.bm-menu-wrap a {
	display: inline-block;
	padding: 1em .5em;
	color: #000;
	box-sizing: border-box;
	text-decoration: none;
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	font-size: 1em;	
	text-align: center;
	outline: none;
}

.bm-menu-wrap a.active {
	border-bottom: 3px solid #ff0000;
}

.bm-menu-wrap a img {
	display: inline-block;
	margin: 0 .5em .1em 0;
	width: 15px;
	vertical-align: middle;
}


@media only screen and (max-width: 90em) {

	.modal {
		width: 85%;
	}
	
}


@media only screen and (max-width: 70em) {

	.modal {
		width: 100%;
	}
	
}

/* TABLET */
@media only screen and (max-width: 60em) {

	.modal {
		width: 100%;
	}

	.modalCentered {
		width: 80%;
	}

	header	.links {
		display: none;
	}
	
	.bm-burger-button {
		display: block;
	}
	
}



@media only screen and (max-width: 51em) {


	.revelsendGrid .gridBox {
		min-width: 92%;
		box-sizing: border-box;
	}

	.colInset {
		margin: 0 auto;
		width: 90%;
		z-index: 100;
		position: relative;
	}
	
}


/* MOBILE */

@media only screen and (max-width: 40em) {

	.modalCentered {
		width: 90%;
	}
	
	.modalSidebar > .description {
		padding: 2em 1em;
	}

	.modalSidebar > .description .col3 {
		padding: 0 2em;
	}

	.modalHeader h2 {
		padding: 2em 0 0 0;
	}

	.embedModal {
		height: calc(100% - 50px);
		top: 50px;
	}
	
	.backLink.rp {
		display: none;
	}
	
	.modalHeader .discord {
		display: none !important;
	}
	
	.revelsendGrid .gridBox .video {
		display: none;
	}
	
	.revelsendGrid .gridBox .videomobile {
		display: block;	
	}
	
}