#map {
	height: 100%;
}

#map .mapImage {
	height: calc(100vw * .715);
}

#map button, .subNodeMap button {
	cursor: pointer;
}

#map .zoomContainer {
	overflow: visible;
	width: 100%;
	height: calc(100% - -1rem - 40px);
	position: absolute;
	box-sizing: border-box;
	border: .5rem solid #fff;
	-webkit-filter: blur(0);
	background: url('./assets/map-bg.svg') repeat;
	background-size: 200%;
}

#map .zoomContainer .react-transform-component {
	overflow: hidden;
	height: 100% !important;
	width: 100% !important;
}

#map .zoomContainer .contentLayer {
	width: 100%;
	height: 100%;
	position: relative;
}

#map .zoomContainer .contentLayer button:focus, .subNodeMap button:focus {
	outline: 0;	
}

#map .zoomContainer .contentLayer button {
	position: absolute;	
	display: block;
	padding: 0;
	opacity: 1;
	font-size: 1vw;
	width: 2vw;
	height: 2vw;
	background-color: transparent;
	border: none;
	transform: translate(50%, 50%);
}

#map .zoomContainer .contentLayer button[hidden] {
	opacity: 0;
	cursor: default;
}

.subNodeMap button {
	position: absolute;	
	display: block;
	padding: 0;
	opacity: 1;
	font-size: 1vw;
	width: 5vw;
	height: 5vw;
	background-color: transparent;
	border: none;
}

.subNodeMap b {
	display: block;
	color: #fff;
	background-color: rgba(0,0,0,.7);
	padding: .2em 1em .1em 1em;
	position: absolute;
	top: 2.5em;
	left: 50%;
	transform: translateX(-50%);
	font-size: 1.4em;
	line-height: 1.5em;
	text-align: center;
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	white-space: nowrap;
	z-index: 1;
}

#map .zoomContainer .contentLayer button:hover .labelHover, .subNodeMap button:hover .labelHover {
	opacity: 1;
}

#map .zoomContainer .contentLayer button img, #map .zoomContainer .contentLayer button svg {
	margin: 0 auto;
	z-index: 10;
	position: relative;
}

.subNodeMap button img, .subNodeMap button img {
	margin: 0 auto;
	z-index: 10;
	position: relative;
}

#map .zoomContainer .contentLayer .count {
	position: absolute;
	top: -20%;
	right: -20%;
	z-index: 100;
	background-color: #ff0000;
	color: #fff;
	border-radius: 50%;
	width: .7vw;
	height: .7vw;
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
	font-size: .6em;
	padding: .15vw;
}

#map .zoomContainer .contentLayer img.nodeName {
	height: 2.5vh;
	position: absolute;
	transform: translateY(-25%) translateX(-50%);
}

img.nodeName path {
	outline: 10px solid red;
}



#map .zoomContainer .contentLayer button div.label {
	position: absolute;
	display: block;
	width: 6vw;
	height: 1.5vw;	
	top: 2em;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
}

#map .zoomContainer .contentLayer img.label, .subNodeMap img.label, #map .zoomContainer .contentLayer img.labelHover, .subNodeMap img.labelHover {
	display: block;
	padding: 0;
	position: absolute;
	font-size: .6em;
	line-height: 1em;
	text-align: center;
	font-family: 'Quattrocento Sans', sans-serif;
	white-space: nowrap;
	z-index: 1;
	height: 2.5vw;
	left: 50%;
	transform: translateY(-20%) translateX(-50%);
}

.subNodeMap img.label, .subNodeMap img.labelHover {
	height: 5vw;
}

#map .zoomContainer .contentLayer img.labelHover, .subNodeMap img.labelHover {
	z-index: 10;
	opacity: 0;
}

#map .zoomContainer .contentLayer .labelTop div.label, .subNodeMap .labelTop div.label, #map .zoomContainer .contentLayer .labelTop div.label, .subNodeMap labelTop div.label {
	top: -1.3em;
}

#map .react-transform-component {
    width: unset !important;
    height: unset !important;
}

#map .react-transform-element {
    width: unset !important;
    height: unset !important;
}

#map img.crest {
    max-width:  100%;
    max-height: 100%;
    display: block;
}

#map .tools {
	position: absolute;
	top: 70px;
	left: 40px;
	z-index: 100;
}

#map .tools button {
	background-color: #fff;
	width: 3em;
	padding: 1em;
	border: 1px solid #000;
	box-shadow: 0 0 10px #fff;
}

#map h1 {
	font-family: 'Quattrocento Sans', sans-serif;
	font-weight: 700;
}